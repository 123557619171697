<template>
  <div class="orderTest-container">
    <el-table stripe :data="tableData">
      <el-table-column
        v-for="(list, index) in columns"
        :key="index"
        :label="list.label"
        :align="list.align"
        :prop="list.prop"
        width=""
      ></el-table-column>
      <el-table-column label="操作" align="center" width="200">
        <template #default="{ row, $index }">
          <el-button type="text" @click="handleredit(row, $index)">
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <selection ref="selection" @getlist="getlist"></selection>
    <universal ref="Universal" @getlist="getlist"></universal>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'DataDelete',
    components: {
      Selection: () => import('./components/Selection.vue'),
      Universal: () => import('./components/Universal.vue'),
    },
    data() {
      return {
        url: {
          list: '/superAdmin/dealer-form/fast-list',
        },
        tableData: [],
        columns: [
          {
            label: '数据类型',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'count',
            width: '',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlerlist()
    },
    methods: {
      getlist() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res)
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleredit(row, index) {
        console.log(row)
        if (!index) {
          this.$refs.selection.selection = true
          this.$refs.selection.handlerbrand()
        } else {
          this.$refs.Universal.Universalshow = true
          switch (index) {
            case 1:
              this.$refs.Universal.handlerCategory()
              this.$refs.Universal.title = '快速品类管理'
              this.$refs.Universal.type = 1
              break
            case 2:
              this.$refs.Universal.handlerchannel()
              this.$refs.Universal.title = '通用渠道管理'
              this.$refs.Universal.type = 2
              break
            case 3:
              this.$refs.Universal.handlerCustomer()
              this.$refs.Universal.title = '通用客户等级'
              this.$refs.Universal.type = 3
              break
            case 4:
              this.$refs.Universal.handlerpictures()
              this.$refs.Universal.title = '通用拍照类型'
              this.$refs.Universal.type = 4
              break
            case 5:
              this.$refs.Universal.handlerpay()
              this.$refs.Universal.title = '通用收款方式'
              this.$refs.Universal.type = 5
              break
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
