var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.tableData } },
        [
          _vm._l(_vm.columns, function (list, index) {
            return _c("el-table-column", {
              key: index,
              attrs: {
                label: list.label,
                align: list.align,
                prop: list.prop,
                width: "",
              },
            })
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleredit(row, $index)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("selection", { ref: "selection", on: { getlist: _vm.getlist } }),
      _c("universal", { ref: "Universal", on: { getlist: _vm.getlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }